<template>
  <div id="preview">
    <v-card>
      <v-form ref="form1" v-model="valid1" lazy-validation>
        <v-card-title>
          <span class="headline">Add New Member</span>
        </v-card-title>
        <v-card-text>
          <h6><span class="text-danger">*</span>indicates required field</h6>
          <h6 class="text-danger">Kindly fill the details in English only</h6>
          <v-container>
            <v-row wrap>
              <v-col cols="12" md="4">
                <label><span class="text-danger">*</span> First Name</label>
                <v-text-field
                  v-model="FirstName"
                  :rules="FirstNameRules"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <label>Middle Name <small>(Optional)</small></label>
                <v-text-field
                  v-model="MiddleName"
                  :rules="MiddleNameRules"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <label>Last Name <small>(Optional)</small></label>
                <v-text-field
                  v-model="LastName"
                  :rules="LastNameRules"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <label><span class="text-danger">*</span> Gender</label>
              <v-select
                :reduce="(option) => option.value"
                :items="GenderOptions"
                :rules="GenderRules"
                v-model="Gender"
                required
                outlined
                dense
              ></v-select>
            </v-col>
            </v-row>
            <v-row wrap>
              <v-col cols="12" md="12">
                <label><span class="text-danger">*</span> Street 1</label>
                <v-text-field
                  v-model="Street1"
                  :rules="Street1Rules"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col cols="12" md="8">
                <label>Street 2 <small>(Optional)</small></label>
                <v-text-field
                  v-model="Street2"
                  :rules="Street2Rules"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <label>Area <small>(Optional)</small></label>
                <v-text-field
                  v-model="Area"
                  :rules="AreaRules"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <label><span class="text-danger">*</span> State</label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="StateIdOptionsLoading"
                  :items="StateIdOptions"
                  :rules="StateIdRules"
                  v-model="StateId"
                  required
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label><span class="text-danger">*</span> District</label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="DistrictIdOptionsLoading"
                  :items="DistrictIdOptions"
                  :rules="DistrictIdRules"
                  v-model="DistrictId"
                  required
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label><span class="text-danger">*</span> City</label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="CityIdOptionsLoading"
                  :items="CityIdOptions"
                  :rules="CityIdRules"
                  v-model="CityId"
                  required
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col cols="12" md="4">
                <label><span class="text-danger">*</span> Pincode</label>
                <v-text-field
                  v-model="Pincode"
                  type="number"
                  :rules="PincodeRules"
                  :counter="6"
                  v-mask="'######'"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <label><span class="text-danger">*</span> Mobile No</label>
                <v-text-field
                  v-model="MobileNo"
                  :rules="MobileNoRules"
                  type="number"
                  :counter="10"
                  v-mask="'##########'"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <label><span class="text-danger">*</span> Email Id</label>
                <v-text-field
                  v-model="EmailId"
                  :rules="EmailIdRules"
                  type="email"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="blue darken-1" text @click="closePrompt">Close</v-btn>
          <v-btn
            color="blue darken-1"
            text
            :loading="SubmitFlag"
            :disabled="!valid1"
            @click="confirmAlert"
            >Save</v-btn
          > -->
          <v-btn
            :disabled="!valid1"
            @click.prevent="submitForm"
            :loading="SubmitFlag"
            elevation="30"
            shaped
            tile
            small
            color="#1db954"
            class="font-size-h6 px-10 mr-3 white--text"
          >
            Save
          </v-btn>
          <v-btn
            @click="closePrompt"
            elevation="30"
            outlined
            small
            color="#1db954"
            class="font-size-h6 px-10 mr-3"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    addRecordPrompt: {
      type: Boolean,
      required: true,
    },
    pageDescription: {
      type: String,
    },
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,

      rows: [],
      member: {},

      FirstName: "",
      FirstNameRules: [(v) => !!v || "First Name is required"],

      MiddleName: "",
      MiddleNameRules: [],

      LastName: "",
      LastNameRules: [],
      
      Gender: "",
      GenderRules: [(v) => !!v || "Gender is required"],
      GenderOptions: [
        { value: "", text: "--Select--" },
        { value: 1, text: "Male" },
        { value: 2, text: "Female" },
        { value: 3, text: "Others" },
      ],

      Street1: "",
      Street1Rules: [(v) => !!v || "Street 1 is required"],

      Street2: "",
      Street2Rules: [],

      Pincode: "",
      PincodeRules: [(v) => !!v || "Pincode is required"],

      Area: "",
      AreaRules: [],

      MobileNo: "",
      MobileNoRules: [],

      EmailId: "",
      EmailIdRules: [],

      StateIdRules: [(v) => !!v || "State is required"],
      StateId: "",
      StateIdOptions: [],
      StateIdOptionsLoading: false,

      DistrictIdRules: [(v) => !!v || "District is required"],
      DistrictId: "",
      DistrictIdOptions: [],
      DistrictIdOptionsLoading: false,

      CityIdRules: [(v) => !!v || "City is required"],
      CityId: "",
      CityIdOptions: [],
      CityIdOptionsLoading: false,
    };
  },
  computed: {},
  watch: {
    StateIdOptions: function () {
      console.log("watch StateIdOptions");
      this.LoadingFlag = false;
      this.StateIdOptionsLoading = false;
    },
    StateId: function () {
      console.log("watch StateId");
      this.LoadingFlag = true;
      this.DistrictIdOptionsLoading = true;
      this.getDistrictIdOptions(
        "DistrictId",
        "DistrictIdOptions",
        this.StateId
      );
    },
    DistrictIdOptions: function () {
      console.log("watch DistrictIdOptions");
      this.LoadingFlag = false;
      this.DistrictIdOptionsLoading = false;
    },
    DistrictId: function () {
      console.log("watch DistrictId");
      this.LoadingFlag = true;
      this.CityIdOptionsLoading = true;
      this.getCityIdOptions("CityId", "CityIdOptions", this.DistrictId);
    },
    CityIdOptions: function () {
      console.log("watch CityIdOptions");
      this.LoadingFlag = false;
      this.CityIdOptionsLoading = false;
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    validateForm() {
      console.log("validateForm called");
      this.$refs.form1.validate();
    },
    resetForm() {
      console.log("resetForm called");
      this.$refs.form1.reset();
      //   this.$refs.ProfilePicture.reset();
    },
    resetValidation() {
      console.log("resetValidation called");
      this.$refs.form1.resetValidation();
    },
    closePrompt() {
      console.log("closePrompt called");
      // this.$emit('Member', this.NewMember);
      this.$emit("hideAddRecordPrompt", this.member);
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.StateIdOptionsLoading = true;
      this.getStateIdOptions("StateId", "StateIdOptions");
    },
    confirmAlert() {
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);
      if (validate1) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Save`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        this.toast("error", "Kindly fill the required fields", true);
      }
    },
    getStateIdOptions(source, destination) {
      console.log("getStateIdOptions called");
      if (source != "" && destination != "") {
        this.LoadingFlag = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/states/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getDistrictIdOptions(source, destination, StateId) {
      console.log("getDistrictIdOptions called StateId=" + StateId);
      if (source != "" && destination != "" && StateId != "") {
        this.LoadingFlag = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/district/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          StatesCode: StateId,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getCityIdOptions(source, destination, DistrictId) {
      console.log("getCityIdOptions called DistrictId=" + DistrictId);
      if (source != "" && destination != "" && DistrictId != "") {
        this.LoadingFlag = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/city/options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          DistrictCode: DistrictId,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      //   validate1 = false;

      if (validate1) {
        this.SubmitFlag = true;
        var FirstName = this.FirstName;
        var MiddleName = this.MiddleName;
        var LastName = this.LastName;
        var MemberName = FirstName + " " + MiddleName + " " + LastName;

        // Get City details

        var CityId = this.CityId;
        var CityIndex = this.CityIdOptions.map((e) => e.value).indexOf(CityId);
        console.log("CityId=" + CityId + ", CityIndex=" + CityIndex);
        var CityName = this.CityIdOptions[CityIndex].text;
        console.log("CityName=" + CityName);

        // Get District details

        var DistrictId = this.DistrictId;
        var DistrictIndex = this.DistrictIdOptions.map((e) => e.value).indexOf(
          DistrictId
        );
        console.log(
          "DistrictId=" + DistrictId + ", DistrictIndex=" + DistrictIndex
        );
        var DistrictName = this.DistrictIdOptions[DistrictIndex].text;
        console.log("DistrictName=" + DistrictName);

        // Get State details

        var StateId = this.StateId;
        var StateIndex = this.StateIdOptions.map((e) => e.value).indexOf(
          StateId
        );
        console.log("StateId=" + StateId + ", StateIndex=" + StateIndex);
        var StateName = this.StateIdOptions[StateIndex].text;
        console.log("StateName=" + StateName);

        var Street1 = this.Street1;
        var Street2 = this.Street2;
        var Area = this.Area;
        var Pincode = this.Pincode;
        var Gender = this.Gender;
        var AddressTxt =
          Street1 +
          ", <br/> " +
          Street2 +
          ", <br/>" +
          Area +
          ", " +
          CityName +
          ", <br/>" +
          DistrictName +
          ", <br/>" +
          StateName +
          " - " +
          Pincode;
        this.member = {
          MemberName: MemberName,
          MemberFirstName: FirstName,
          MemberMiddleName: MiddleName,
          MemberLastName: LastName,
          MemberEmail: this.EmailId,
          MemberMobileNo: this.MobileNo,
          MemberStreet1: Street1,
          MemberStreet2: Street2,
          MemberArea: Area,
          MemberAddressTxt: AddressTxt,
          MemberCity: CityId,
          MemberPincode: Pincode,
          MemberFlag: true,
          MemberGender: Gender
        };
        console.log("member=" + JSON.stringify(this.member));
        var output = "Member added successfully. ";
        // this.SubmitFlag = false;
        this.resetForm();
        this.closePrompt();
        this.sweetAlert("success", output, true);
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
    this.member = {};
  },
};
</script>
